<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

const auth = useAuth()
const user = auth.data.value?.user
let refreshTries = 0;
const hasNotifications = ref(false);
const { data, status, error: fetchError, refresh: refreshJWT } = await useAsyncData(
    'mercureJwtToken',
    () => $fetch('/api/get/mercure-token'),
    {
      dedupe: 'defer',
    }
)
let userToken = data.value

// Funktion zur Verbindung mit Mercure
const connectToMercure = async () => {
  // const hubUrl = data.value?.headers.get('Link').match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)[1];
  const hubUrl = 'https://localhost/.well-known/mercure'; // TODO get from env
  // Append the topic(s) to subscribe as query parameter
  if (hubUrl) {
    userToken = data.value
    const hub = new URL(hubUrl, window.origin);
    hub.searchParams.append('topic', 'https://localhost/notifications/user/'+ user?.uid); // TODO: replace with env

    // Subscribe to updates
    const eventSource = new EventSourcePolyfill(hub, {
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + userToken,
      }
    });

    eventSource.onmessage = (event) => {
      console.log(event.data)
      const data = JSON.parse(event.data);

      // TODO: Trigger ToastMessage mit Daten
      console.log('Neue Benachrichtigung:', data);

      hasNotifications.value = true;
    };

    eventSource.onerror = async (error) => {
      console.error('Mercure Verbindung Fehler:', error);
      if (error.status === 401 && refreshTries < 3) {
        refreshTries++;
        //refresh jwt
        await refreshJWT()
        await connectToMercure()
      } else {
        // TODO: redirect to login?
        console.error('Could not refresh mercure');
      }
    };
  }
};

onMounted(() => {
  connectToMercure();
});
</script>

<template>
  <div class="bg-custom-red p-10 w-100" v-if="hasNotifications">
    <p >Neue Benachrichtigungen verfügbar!</p>
  </div>
</template>

<style scoped>
</style>
