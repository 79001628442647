<template>
  <div>
    <button
        :disabled="isIncompatible || isComputing"
        @click="handlePushSubscription"
    >
      {{ buttonText }}
    </button>

    <button v-if="isPushEnabled" >
      PUSH ENABLED
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

// Application server key for push notifications
//TODO get public key from nuxt runtime config
const applicationServerKey =
    'BNM2qVZ28Hs0Ju_bTXH1HXcfwAezIUD5Cj9CRvWQRKUGc1B2xz8remx_lotSN7i51moXaR5EAMf7h2_n0wS3-NY';

// Reactive state variables
const isPushEnabled = ref(false);
const isIncompatible = ref(false);
const isComputing = ref(false);
const buttonText = ref('Enable Push Notifications');

onMounted(() => {
  // Check if service worker and push notifications are supported
  if (!('serviceWorker' in navigator)) {
    isIncompatible.value = true;
    buttonText.value = 'Service workers are not supported';
    return;
  }

  if (!('PushManager' in window)) {
    isIncompatible.value = true;
    buttonText.value = 'Push notifications are not supported';
    return;
  }

  // Register the service worker
  navigator.serviceWorker.register('/serviceWorker.js')
      .then(() => {
        console.log('[SW] Service worker has been registered');
        pushUpdateSubscription();
      })
      .catch((error) => {
        console.error('[SW] Service worker registration failed', error);
        isIncompatible.value = true;
        buttonText.value = 'Service worker registration failed';
      });
});

// Function to convert the VAPID public key from base64 to Uint8Array
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  return new Uint8Array([...rawData].map((char) => char.charCodeAt(0)));
}

// Check notification permission and request if needed
async function checkNotificationPermission() {
  if (Notification.permission === 'denied') {
    throw new Error('Push messages are blocked.');
  }

  if (Notification.permission === 'default') {
    const result = await Notification.requestPermission();
    if (result !== 'granted') {
      throw new Error('Permission not granted.');
    }
  }
}

// Function to subscribe to push notifications
async function pushSubscribe() {
  isComputing.value = true;
  buttonText.value = 'Loading...';

  try {
    await checkNotificationPermission();

    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
    });

    if (subscription) {
      buttonText.value = 'Disable Push Notifications';
      isPushEnabled.value = true;
      const { data, status } = await useFetch('/api/me/push-subscriptions', {
        method: 'POST',
        body: {
          subscription
        },
      });
      if (status.value !== 'success') {
        // TODO: show info?
      } else {
      }
    }
  } catch (error) {
    console.error('Failed to subscribe to push notifications', error);
    buttonText.value = 'Enable Push Notifications';
    isPushEnabled.value = false;
  } finally {
    isComputing.value = false;
  }
}

// Function to update the subscription state
async function pushUpdateSubscription() {
  try {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    const subscription = await serviceWorkerRegistration.pushManager.getSubscription();

    if (subscription) {
      buttonText.value = 'Disable Push Notifications';
      isPushEnabled.value = true;
      const { data, status } = await useFetch('/api/me/push-subscriptions', {
        method: 'PATCH',
        body: {
          subscription
        },
      });
      if (status.value !== 'success') {
        // TODO: show info?
      } else {
      }
    } else {
      buttonText.value = 'Enable Push Notifications';
      isPushEnabled.value = false;
    }
  } catch (error) {
    console.error('Error when updating the subscription', error);
    buttonText.value = 'Enable Push Notifications';
    isPushEnabled.value = false;
  }
}

// Function to unsubscribe from push notifications
async function pushUnsubscribe() {
  isComputing.value = true;
  buttonText.value = 'Loading...';

  try {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    const subscription = await serviceWorkerRegistration.pushManager.getSubscription();

    if (subscription) {
      await subscription.unsubscribe();
      buttonText.value = 'Enable Push Notifications';
      isPushEnabled.value = false;
      const { data, status } = await useFetch('/api/me/push-subscriptions', {
        method: 'DELETE',
        body: {
          subscription
        },
      });
      if (status.value !== 'success') {
        // TODO: show info?
      } else {
      }
    }
  } catch (error) {
    console.error('Error when unsubscribing from push notifications', error);
  } finally {
    isComputing.value = false;
  }
}

// Function to handle the push button click
function handlePushSubscription() {
  if (isPushEnabled.value) {
    pushUnsubscribe();
  } else {
    pushSubscribe();
  }
}

</script>

<style scoped>
button {
  padding: 10px 20px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
